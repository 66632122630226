import Vue from 'vue'
import VueRouter from 'vue-router'
// vue-cookies配置
import VueCookies from 'vue-cookies'
import store from '@/store/index.js'

const Login = () => import('../views/login/Login')
const UniLogin = () => import('../views/login/UniLogin')
const Index = () => import('../views/index/Index')
const Home = () => import('../views/home/Home')
const HomePage = () => import('../views/home/childComps/HomePage')
const Personal = () => import('../views/personal/Personal')
const PasswordSetting = () => import('../views/personal/PasswordSetting')
// 产品管理
// 机构管理
const FirstOrganList = () => import('../views/product/organ_management/FirstOrganList')
const AddOrEditFirstOrg = () => import('../views/product/organ_management/childComps/AddOrEditFirstOrg')
const SecondOrganList = () => import('../views/product/organ_management/SecondOrganList')
const AddOrEditSecondOrg = () => import('../views/product/organ_management/childComps/AddOrEditSecondOrg')
const OrgRegistriesList = () => import('../views/product/organ_management/OrgRegistriesList')
const OrgExclusive = () => import('../views/product/organ_management/OrgExclusive')
const OrgExclusiveOpen = () => import('../views/product/organ_management/childComps/OrgExclusiveOpen')
const OrgExclusivePage = () => import('../views/product/organ_management/childComps/OrgExclusivePage')
// 用户管理
const PersonalAccountList = () => import('../views/product/user_management/PersonalAccountList')
const OrgAccountList = () => import('../views/product/user_management/organ_backend/OrgAccountList')
const AddOrEditOrgAccount = () => import('../views/product/user_management/organ_backend/childComps/AddOrEditOrgAccount')
const OrgAccountReviewList = () => import('../views/product/user_management/organ_backend/OrgAccountReviewList')

const OrgFeedbackList = () => import('../views/product/user_management/organ_backend/OrgFeedbackList')
const HandleOrgFeedback = () => import('../views/product/user_management/organ_backend/childComps/HandleOrgFeedback')
const UserRegistriesList = () => import('../views/product/user_management/UserRegistriesList')
const UserFeedbackList = () => import('../views/product/user_management/UserFeedbackList')
const UserHandleFeedback = () => import('../views/product/user_management/childComps/UserHandleFeedback')
const UserReportList = () => import('../views/product/user_management/UserReportList')
const UserHandleReport = () => import('../views/product/user_management/childComps/UserHandleReport')
const UserOnlineTime = () => import('../views/product/user_management/UserOnlinetime')

// 消息系统
const ChatMessage = () => import('../views/product/message_system/ChatMessage')
const SystemMessageList = () => import('../views/product/message_system/SystemMessageList')
const AddOrEditMessage = () => import('../views/product/message_system/childComps/AddOrEditMessage')
// 资讯动态
const NewsList = () => import('../views/product/news/NewsList')
const AddOrEditNews = () => import('../views/product/news/childComps/AddOrEditNews')
const ApproveNewList = () => import('../views/product/news/ApproveNewList')

// 官网首页管理
const BannerList = () => import('../views/product/website_show/BannerList')
const AddOrEditBanner = () => import('../views/product/website_show/childComps/AddOrEditBanner')
const FriendlyLinkList = () => import('../views/product/website_show/FriendlyLinkList')

// 帮助中心管理
const HelpCatalogueList = () => import('../views/product/help_center/HelpCatalogueList')
const AddOrEditArticle = () => import('../views/product/help_center/childComps/AddOrEditArticle')
const HelpUserFeedbackList = () => import('../views/product/help_center/HelpUserFeedbackList')

// 活动管理
const ActivityType = () => import('../views/activity/ActivityType')
const ActivityList = () => import('../views/activity/ActivityList')
const ApprovalList = () => import('../views/activity/ApprovalList')
const ActivityConstruction = () => import('../views/activity/FormLayout')
const ProSetting = () => import('../views/activity/pro_setting/ProSetting')
const WorksActivityList = () => import('../views/activity/collect_work/WorksActivityList')
const CollectWorkList = () => import('../views/activity/collect_work/components/process/CollectWorkList')
const CollectWorkApprovalDetail = () => import('../views/activity/collect_work/components/process/CollectWorkApprovalDetail')
const CollectWorkDetail = () => import('../views/activity/collect_work/components/process/CollectWorkDetail')
const ReviewSystemList = () => import('../views/activity/collect_work/ReviewSystemList')
const AddOrEditElectSystem = () => import('../views/activity/collect_work/components/review_system/AddOrEditElectSystem')
const AddOrEditScoreSystem = () => import('../views/activity/collect_work/components/review_system/AddOrEditScoreSystem')
const AddOrEditVoteSystem = () => import('../views/activity/collect_work/components/review_system/AddOrEditVoteSystem')
const ActElect = () => import('../views/activity/collect_work/components/review_system/ActElect')
const WorksScoreList = () => import('../views/activity/collect_work/components/review_system/WorksScoreList')
const WorksScoreRateList = () => import('../views/activity/collect_work/components/review_system/WorksScoreRateList')
const WorksScoreRankList = () => import('../views/activity/collect_work/components/review_system/WorksScoreRankList')
const WorksVoteList = () => import('../views/activity/collect_work/components/review_system/WorksVoteList')
const WorksVoteRateList = () => import('../views/activity/collect_work/components/review_system/WorksVoteRateList')
const WorksVoteRankList = () => import('../views/activity/collect_work/components/review_system/WorksVoteRankList')
const OrgElectList = () => import('../views/activity/collect_work/components/review_system/OrgElectList')
const WorksElectList = () => import('../views/activity/collect_work/components/review_system/WorksElectList')
const ClockDetail = () => import('../views/activity/tick_off/TickOffDetail')

// 奖证管理
const AwardManage = () => import('../views/activity/award_management/AwardManage')
const AddOrEditAward = () => import('../views/activity/award_management/childComps/AddOrEditAward')
const AwardList = () => import('../views/activity/award_management/AwardList')
const ECertificate = () => import('../views/activity/award_management/ECertificate')
const AddOrEditCertificate = () => import('../views/activity/award_management/childComps/AddOrEditCertificate')
const CertificateDesign = () => import('../views/activity/award_management/childComps/CertificateDesign')
const ImportAwards = () => import('../views/activity/award_management/childComps/ImportAwards')

// 数据管理
// 活动数据
const ActivityDataList = () => import('../views/data/activity_management/ActivityDataList')
const AccessingDataList = () => import('../views/data/activity_management/childComps/AccessingDataList')
const EnrollDataList = () => import('../views/data/activity_management/childComps/EnrollDataList')
const QaActEnrollDataList = () => import('../views/data/activity_management/childComps/QaActEnrollDataList')
const WorksDataList = () => import('../views/data/activity_management/childComps/WorksDataList')
const ActOrgDataList = () => import('../views/data/activity_management/childComps/ActOrgDataList')
const ClockRecordList = () => import('../views/data/activity_management/childComps/ClockRecordList')
const LookClockRecord = () => import('../views/data/activity_management/childComps/LookClockRecord')

const VoteDataList = () => import('../views/data/activity_management/childComps/VoteDataList')
const QaDataList = () => import('../views/data/activity_management/childComps/QaDataList')
const SubjectiveGrade = () => import('../views/data/activity_management/childComps/SubjectiveGrade')
const QaDetail = () => import('../views/data/activity_management/childComps/QaDetail')
const AnswerDataList = () => import('../views/data/activity_management/childComps/AnswerDataList')
// 用户数据
const UserDataList = () => import('../views/data/user_management/UserDataList')
// 机构数据
const OrgDataList = () => import('../views/data/org_management/OrgDataList')
// 访问数据
const VisitDataList = () => import('../views/data/visit_management/VisitDataList')

// 系统管理
// 系统账号管理
const SystemAccountList = () => import('../views/system/system_account/SystemAccountList')
const AddOrEditSystemAccount = () => import('../views/system/system_account/childComps/AddOrEditSystemAccount')
const CorporateStructureView = () => import('../views/system/system_account/CorporateStructureView')
const MenuList = () => import('../views/system/menu_management/MenuList')
const RoleList = () => import('../views/system/role_management/RoleList')
const AddOrEditSystemRole = () => import('../views/system/role_management/childComps/AddOrEditSystemRole')
const SystemLogList = () => import('../views/system/log_management/SystemLogList')
const VersionManage = () => import('../views/system/version_management/VersionManage')
// AI
const AIDraw = () => import('../views/intelligence/AIDraw')

// 易本书
const ExBookApprovalList = () => import('../views/exbook/BookApprovalList')
const NearLibApprovalList = () => import('../views/exbook/NearLibApprovalList')
const CuratorApprovalList = () => import('../views/exbook/CuratorApprovalList')
const UserTitleTypeManageList = () => import('../views/exbook/UserTitleTypeManageList')
const AddUserTitleType = () => import('../views/exbook/childComps/AddUserTitleType')
const ScoreExchangeApprovalList = () => import('../views/exbook/ScoreExchangeApprovalList')
const ScoreGoodsManageList = () => import('../views/exbook/ScoreGoodsManageList')
const AddOrEditGoods = () => import('../views/exbook/childComps/AddOrEditGoods')
const LibraryEntryApplyList = () => import('../views/exbook/LibraryEntryApplyList')

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        redirect: '/index'
    },
    {
        path: '/uni_login',
        name: 'UniLogin',
        component: UniLogin
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/index',
        name: 'Index',
        component: Index,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: Home,
                children: [
                    {
                        path: '/first_organlist',
                        name: 'FirstOrganList',
                        component: FirstOrganList,
                        meta: { title: '一级单位' }
                    },
                    {
                        path: '/first_organlist/addoredit_firstorg',
                        name: 'AddOrEditFirstOrg',
                        component: AddOrEditFirstOrg,
                        meta: { title: '添加/修改一级单位' }
                    },
                    {
                        path: '/second_organlist',
                        name: 'SecondOrganList',
                        component: SecondOrganList,
                        meta: { title: '二级单位' }
                    },
                    {
                        path: '/second_organlist/addoredit_secondorg',
                        name: 'AddOrEditSecondOrg',
                        component: AddOrEditSecondOrg,
                        meta: { title: '添加/修改二级单位' }
                    },
                    {
                        path: '/org_registries_list',
                        name: 'OrgRegistriesList',
                        component: OrgRegistriesList,
                        meta: { title: '机构登录记录' }
                    },
                    {
                        path: '/personal_account_list',
                        name: 'PersonalAccountList',
                        component: PersonalAccountList,
                        meta: { title: '个人账号管理' }
                    },
                    {
                        path: '/org_account_list',
                        name: 'OrgAccountList',
                        component: OrgAccountList,
                        meta: { title: '机构账号管理' }
                    },
                    {
                        path: '/org_account_list/addoredit_orgaccount',
                        name: 'AddOrEditOrgAccount',
                        component: AddOrEditOrgAccount,
                        meta: { title: '添加/修改机构账号' }
                    },
                    {
                        path: '/org_account_reviewlist',
                        name: 'OrgAccountReviewList',
                        component: OrgAccountReviewList,
                        meta: { title: '机构账号申请审核' }
                    },
                    {
                        path: '/org_exclusive',
                        name: 'OrgExclusive',
                        component: OrgExclusive,
                        meta: { title: '机构专属页管理' }
                    },
                    {
                        path: '/org_exclusive_open',
                        name: 'OrgExclusiveOpen',
                        component: OrgExclusiveOpen,
                        meta: { title: '机构专属页开通' }
                    },
                    {
                        path: '/org_exclusive_page',
                        name: 'OrgExclusivePage',
                        component: OrgExclusivePage,
                        meta: { title: '机构专属页页面配置' }
                    },
                    {
                        path: '/user_registries_list',
                        name: 'UserRegistriesList',
                        component: UserRegistriesList,
                        meta: { title: '个人登录记录' }
                    },
                    {
                        path: '/user_online_time',
                        name: 'UserOnlineTime',
                        component: UserOnlineTime,
                        meta: { title: '在线时长统计' }
                    },
                    {
                        path: '/org_feedback_list',
                        name: 'OrgFeedbackList',
                        component: OrgFeedbackList,
                        meta: { title: '机构用户反馈' }
                    },
                    {
                        path: '/org_feedback_list/handle_org_feedback',
                        name: 'HandleOrgFeedback',
                        component: HandleOrgFeedback,
                        meta: { title: '机构用户反馈处理' }
                    },
                    {
                        path: '/user_feedback_list',
                        name: 'UserFeedbackList',
                        component: UserFeedbackList,
                        meta: { title: '用户反馈' }
                    },
                    {
                        path: '/user_feedback_list/user_handle_feedback',
                        name: 'UserHandleFeedback',
                        component: UserHandleFeedback,
                        meta: { title: '用户反馈处理' }
                    },
                    {
                        path: '/user_report_list',
                        name: 'UserReportList',
                        component: UserReportList,
                        meta: { title: '用户举报' }
                    },
                    {
                        path: '/user_report_list/user_handle_report',
                        name: 'UserHandleReport',
                        component: UserHandleReport,
                        meta: { title: '用户举报处理' }
                    },
                    {
                        path: '/chat_message',
                        name: 'ChatMessage',
                        component: ChatMessage,
                        meta: { title: '互动消息' }
                    },
                    {
                        path: '/message_list',
                        name: 'SystemMessageList',
                        component: SystemMessageList,
                        meta: { title: '系统消息' }
                    },
                    {
                        path: '/message_list/addoredit_message',
                        name: 'AddOrEditMessage',
                        component: AddOrEditMessage,
                        meta: { title: '添加/修改系统消息' }
                    },
                    {
                        path: '/news_list',
                        name: 'NewsList',
                        component: NewsList,
                        meta: { title: '资讯动态' }
                    },
                    {
                        path: '/news_list/addoredit_news',
                        name: 'AddOrEditNews',
                        component: AddOrEditNews,
                        meta: { title: '添加/修改资讯动态' }
                    },
                    {
                        path: '/approve_new_list',
                        name: 'ApproveNewList',
                        component: ApproveNewList,
                        meta: { title: '资讯审核' }
                    },
                    {
                        path: '/banner_list',
                        name: 'BannerList',
                        component: BannerList,
                        meta: { title: '添加/修改Banner' }
                    },
                    {
                        path: '/banner_list/addoredit_banner',
                        name: 'AddOrEditBanner',
                        component: AddOrEditBanner,
                        meta: { title: 'Banner位' }
                    },
                    {
                        path: '/friendly_link_list',
                        name: 'FriendlyLinkList',
                        component: FriendlyLinkList,
                        meta: { title: '友情链接' }
                    },
                    {
                        path: '/help_catalogue_list',
                        name: 'HelpCatalogueList',
                        component: HelpCatalogueList,
                        meta: { title: '帮助中心管理' }
                    },
                    {
                        path: '/help_catalogue_list/addoredit_article',
                        name: 'AddOrEditArticle',
                        component: AddOrEditArticle,
                        meta: { title: '添加文章' }
                    },
                    {
                        path: '/help_user_feedback_list',
                        name: 'HelpUserFeedbackList',
                        component: HelpUserFeedbackList,
                        meta: { title: '帮助中心用户反馈数据' }
                    },
                    {
                        path: '/activity_type',
                        name: 'ActivityType',
                        component: ActivityType,
                        meta: { title: '发布活动' }
                    },
                    {
                        path: '/activity/list',
                        name: 'ActivityList',
                        component: ActivityList,
                        meta: { title: '活动列表' }
                    },
                    {
                        path: '/activity/approval/list',
                        name: 'ApprovalList',
                        component: ApprovalList,
                        meta: { title: '活动创建审核' }
                    },
                    {
                        path: '/activity/award',
                        name: 'AwardManage',
                        component: AwardManage,
                        meta: { title: '奖项管理' }
                    },
                    {
                        path: '/activity/addoredit_award',
                        name: 'AddOrEditAward',
                        component: AddOrEditAward,
                        meta: { title: '' }
                    },
                    {
                        path: '/activity/import_awards',
                        name: 'ImportAwards',
                        component: ImportAwards,
                        meta: { title: '导入获奖名单' }
                    },
                    {
                        path: '/activity/award_list',
                        name: 'AwardList',
                        component: AwardList,
                        meta: { title: '获奖名单' }
                    },
                    {
                        path: '/activity/e_certificates',
                        name: 'ECertificate',
                        component: ECertificate,
                        meta: { title: '电子证书' }
                    },
                    {
                        path: '/activity/addoredit_certificate',
                        name: 'AddOrEditCertificate',
                        component: AddOrEditCertificate,
                        meta: { title: '' }
                    },
                    {
                        path: '/activity/certificate_design',
                        name: 'CertificateDesign',
                        component: CertificateDesign,
                        meta: { title: '证书设计' }
                    },
                    {
                        path: '/activity/create/:type',
                        name: 'ActivityCreate',
                        component: ActivityConstruction,
                        meta: { title: '活动创建' }
                    },
                    {
                        path: '/activity/modify/:type/:id',
                        name: 'ActivityModify',
                        component: ActivityConstruction,
                        meta: { title: '活动修改' }
                    },
                    {
                        path: '/activity/pro_setting',
                        name: 'ProSetting',
                        component: ProSetting,
                        meta: { title: '高级设置' }
                    },
                    {
                        path: '/works_activity_list',
                        name: 'WorksActivityList',
                        component: WorksActivityList,
                        meta: { title: '征集类活动列表' }
                    },
                    {
                        path: '/works_activity_list/collect_work_list',
                        name: 'CollectWorkList',
                        component: CollectWorkList,
                        meta: { title: '征集类活动作品审核列表' }
                    },
                    {
                        path: '/works_activity_list/collect_work_approval_detail',
                        name: 'CollectWorkApprovalDetail',
                        component: CollectWorkApprovalDetail,
                        meta: { title: '征集类活动作品审核详情' }
                    },
                    {
                        path: '/works_activity_list/collect_work_detail',
                        name: 'CollectWorkDetail',
                        component: CollectWorkDetail,
                        meta: { title: '征集类活动作品详情' }
                    },
                    {
                        path: '/review_system_list',
                        name: 'ReviewSystemList',
                        component: ReviewSystemList,
                        meta: { title: '征集类评审系统列表' }
                    },
                    {
                        path: '/review_system_list/add_or_edit_electsystem',
                        name: 'AddOrEditElectSystem',
                        component: AddOrEditElectSystem,
                        meta: { title: '征集类新建作品推选' }
                    },
                    {
                        path: '/review_system_list/add_or_edit_scoresystem',
                        name: 'AddOrEditScoreSystem',
                        component: AddOrEditScoreSystem,
                        meta: { title: '征集类新建评分系统' }
                    },
                    {
                        path: '/review_system_list/add_or_edit_votesystem',
                        name: 'AddOrEditVoteSystem',
                        component: AddOrEditVoteSystem,
                        meta: { title: '征集类新建投票系统' }
                    },
                    {
                        path: '/review_system_list/act_elect',
                        name: 'ActElect',
                        component: ActElect,
                        meta: { title: '征集类作品推选' }
                    },
                    {
                        path: '/review_system_list/works_score_list',
                        name: 'WorksScoreList',
                        component: WorksScoreList,
                        meta: { title: '征集类查看评分情况' }
                    },
                    {
                        path: '/review_system_list/works_score_rate_list',
                        name: 'WorksScoreRateList',
                        component: WorksScoreRateList,
                        meta: { title: '征集类作品评审进度' }
                    },
                    {
                        path: '/review_system_list/works_score_rank_list',
                        name: 'WorksScoreRankList',
                        component: WorksScoreRankList,
                        meta: { title: '征集类作品得分排名' }
                    },
                    {
                        path: '/review_system_list/works_vote_list',
                        name: 'WorksVoteList',
                        component: WorksVoteList,
                        meta: { title: '征集类作品投票情况' }
                    },
                    {
                        path: '/review_system_list/works_vote_rate_list',
                        name: 'WorksVoteRateList',
                        component: WorksVoteRateList,
                        meta: { title: '征集类作品投票进度' }
                    },
                    {
                        path: '/review_system_list/works_vote_rank_list',
                        name: 'WorksVoteRankList',
                        component: WorksVoteRankList,
                        meta: { title: '征集类作品票数排名' }
                    },
                    {
                        path: '/review_system_list/org_elect_list',
                        name: 'OrgElectList',
                        component: OrgElectList,
                        meta: { title: '征集类单位推选情况' }
                    },
                    {
                        path: '/review_system_list/works_elect_list',
                        name: 'WorksElectList',
                        component: WorksElectList,
                        meta: { title: '征集类作品推选情况' }
                    },
                    {
                        path: '/clock_detail',
                        name: 'ClockDetail',
                        component: ClockDetail,
                        meta: { title: '打卡类活动打卡详情' }
                    },
                    {
                        path: '/activity_data_list',
                        name: 'ActivityDataList',
                        component: ActivityDataList,
                        meta: { title: '活动数据' }
                    },
                    {
                        path: '/accessing_data_list',
                        name: 'AccessingDataList',
                        component: AccessingDataList,
                        meta: { title: '活动访问数据' }
                    },
                    {
                        path: '/enroll_data_list',
                        name: 'EnrollDataList',
                        component: EnrollDataList,
                        meta: { title: '报名数据' }
                    },
                    {
                        path: '/qa_act_enroll_data_list',
                        name: 'QaActEnrollDataList',
                        component: QaActEnrollDataList,
                        meta: { title: '答题活动报名数据' }
                    },
                    {
                        path: '/works_data_list',
                        name: 'WorksDataList',
                        component: WorksDataList,
                        meta: { title: '作品数据' }
                    },
                    {
                        path: '/act_org_data_list',
                        name: 'ActOrgDataList',
                        component: ActOrgDataList,
                        meta: { title: '机构报名数据' }
                    },
                    {
                        path: '/clock_record_list',
                        name: 'ClockRecordList',
                        component: ClockRecordList,
                        meta: { title: '打卡数据' }
                    },
                    {
                        path: '/look_clock_record',
                        name: 'LookClockRecord',
                        component: LookClockRecord,
                        meta: { title: '查看完整打卡数据' }
                    },
                    {
                        path: '/vote_data_list',
                        name: 'VoteDataList',
                        component: VoteDataList,
                        meta: { title: '投票数据' }
                    },
                    {
                        path: '/qa_data_list',
                        name: 'QaDataList',
                        component: QaDataList,
                        meta: { title: '问卷数据' }
                    },
                    ,
                    {
                        path: '/answer_data_list',
                        name: 'AnswerDataList',
                        component: AnswerDataList,
                        meta: { title: '答卷数据' }
                    },
                    {
                        path: '/qa_detail/:id',
                        name: 'QaDetail',
                        component: QaDetail,
                        meta: { title: '答卷详情' }
                    },
                    {
                        path: '/subjective_grade/:id',
                        name: 'SubjectiveGrade',
                        component: SubjectiveGrade,
                        meta: { title: '主观题评分' }
                    },
                    {
                        path: '/user_data_list',
                        name: 'UserDataList',
                        component: UserDataList,
                        meta: { title: '用户数据' }
                    },
                    {
                        path: '/org_data_list',
                        name: 'OrgDataList',
                        component: OrgDataList,
                        meta: { title: '机构数据' }
                    },
                    {
                        path: '/visit_data_list',
                        name: 'VisitDataList',
                        component: VisitDataList,
                        meta: { title: '访问数据' }
                    },
                    {
                        path: '/account_list',
                        name: 'SystemAccountList',
                        component: SystemAccountList,
                        meta: { title: '系统账号' }
                    },
                    {
                        path: '/account_list/addoredit_systemaccount',
                        name: 'AddOrEditSystemAccount',
                        component: AddOrEditSystemAccount,
                        meta: { title: '添加/修改系统账号' }
                    },
                    {
                        path: '/account_list/corporate_structure',
                        name: 'CorporateStructureView',
                        component: CorporateStructureView,
                        meta: { title: '公司架构设置' }
                    },
                    {
                        path: '/menu_list',
                        name: 'MenuList',
                        component: MenuList,
                        meta: { title: '菜单管理' }
                    },
                    {
                        path: '/role_list',
                        name: 'RoleList',
                        component: RoleList,
                        meta: { title: '角色管理' }
                    },
                    {
                        path: '/role_list/addoredit_system_role',
                        name: 'AddOrEditSystemRole',
                        component: AddOrEditSystemRole,
                        meta: { title: '角色管理' }
                    },
                    {
                        path: '/log_list',
                        name: 'SystemLogList',
                        component: SystemLogList,
                        meta: { title: '日志管理' }
                    },
                    {
                        path: '/version_manage',
                        name: 'VersionManage',
                        component: VersionManage,
                        meta: { title: '版本管理' }
                    },
                    {
                        path: '/exbook/book_approval_list',
                        name: 'ExBookApprovalList',
                        component: ExBookApprovalList,
                        meta: { title: '图书上传审核' }
                    },
                    {
                        path: '/exbook/nearlib_approval_list',
                        name: 'NearLibApprovalList',
                        component: NearLibApprovalList,
                        meta: { title: '书馆创建审核' }
                    },
                    {
                        path: '/exbook/curator_approval_list',
                        name: 'CuratorApprovalList',
                        component: CuratorApprovalList,
                        meta: { title: '馆长申请审核' }
                    },
                    {
                        path: '/exbook/user_title_type_manage_list',
                        name: 'UserTitleTypeManageList',
                        component: UserTitleTypeManageList,
                        meta: { title: '藏家头衔管理' }
                    },
                    {
                        path: '/exbook/add_usertitle_type',
                        name: 'AddUserTitleType',
                        component: AddUserTitleType,
                        meta: { title: '授予头衔' }
                    },
                    {
                        path: '/exbook/score_exchange_approval_list',
                        name: 'ScoreExchangeApprovalList',
                        component: ScoreExchangeApprovalList,
                        meta: { title: '积分兑换审核' }
                    },
                    {
                        path: '/exbook/score_goods_manage_list',
                        name: 'ScoreGoodsManageList',
                        component: ScoreGoodsManageList,
                        meta: { title: '积分商品管理' }
                    },
                    {
                        path: '/exbook/addoredit_goods/:id?',
                        name: 'AddOrEditGoods',
                        component: AddOrEditGoods,
                        meta: { title: '积分商品管理' }
                    },
                    {
                        path: '/exbook/library_entry_apply_list',
                        name: 'LibraryEntryApplyList',
                        component: LibraryEntryApplyList,
                        meta: { title: '图书馆入驻申请' }
                    }
                ]
            },
            {
                path: '/homepage',
                name: 'HomePage',
                component: HomePage,
                meta: { title: '首页' }
            },
            {
                path: '/personal',
                name: 'Personal',
                component: Personal,
                meta: { title: '个人资料' }
            },
            {
                path: '/password_setting',
                name: 'PasswordSetting',
                component: PasswordSetting,
                meta: { title: '修改密码' }
            },
            {
                path: '/ai_draw',
                name: 'AIDraw',
                component: AIDraw,
                meta: { title: 'AI绘图' }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//挂载路由导航守卫
router.beforeEach((to, form, next) => {
    let routerList = ['/uni_login']
    routerList.map((item) => {
        if (item == to.path) {
            store.commit('changeActiveIndex', '')
            VueCookies.set('activeIndex', '')
        }
    })
    // 如果要访问的是登录页则直接放行
    if (to.path == '/login' || to.path == '/uni_login') return next()
    // 获取token
    const tokenStr = VueCookies.get('token')
    // 如果没有token则代表当前尚未登录，强制跳转到登录页
    if (!tokenStr) return next('/uni_login')
    // 如果没有被强制跳转到登录页，则放行到目标页
    next()
})

export default router
